import { FC } from 'react';
import { Box } from '@mui/material';

import ImageProps from './types';

const Image: FC<ImageProps> = ({ src, srcSet, alt, loading, ...props }) => {
    return <Box component="img" src={src} srcSet={srcSet} alt={alt} loading={loading} {...props} />;
};

Image.defaultProps = {
    loading: 'lazy',
};

export default Image;
